import Button from "common/Components/Button/Button";
import React, {useState, useEffect} from "react";
import axiosInstance from "api/axios";
import "../Waste-Dashboard/WasteDashboard.css";
import {AiOutlineArrowDown, AiOutlineArrowUp} from 'react-icons/ai';
import {useContext} from "react";
import {LanguageContext} from "context/LanguageContext";
import ImageDisplay from "./Components/Image-Display/ImageDisplay";
import Waste from "./Waste";
import SavingsChart from "pages/CO2-Savings/SavingsChart";
import SubclassDisplay from "pages/Subclass-Display/SubclassDisplay copy";
import BubbleChart from "pages/Waste-Dashboard/Components/Waste-Bubblechart/BubbleChart";
import BinLineChart from "./Components/Bin-Linechart/BinLineChart";
import useId from "./Components/useId";
import WasteGeneration from "./Components/Waste-Generation/WasteGeneration";
import {debounce} from 'lodash';
import MainDashboard from "../Main-Dashboard/MainDashboard";


const Dashboard = ({id_, wasteitem_, selectedValue_, dayvalue_, datevalue_}) => {
    const wasteitem = wasteitem_ || 'organic';
    const id = id_;
    const [selectedValue, setSelectedValue] = selectedValue_;
    const [dayvalue, setDayValue] = dayvalue_;
    const [selectedButton, setSelectedButton] = useState('year');
    const [binIds, setBinIds] = useState([]);
    const [selectedstream, setSelectedStream] = useState("organic");
    const [selectedChart, setSelectedChart] = useState("waste");
    const [currentWasteItem, setCurrentWasteItem] = useState(wasteitem);
    const [currentbinid, setCurrentBinId] = useState(id);
    const [totalwasteproduced, setTotalWasteProduced] = useState()
    const [colorwaste, setColorWaste] = useState()
    const [percentagechange, setPercentageChange] = useState()
    const {language} = useContext(LanguageContext);
    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const [bin, setBin] = useState()
    const [tier, setTier] = useState('')
    const [TotalWasteWeight, setTotalWasteWeight] = useState('')
    const [datevalue, setDateValue] = datevalue_; // Savings
    const [calendarclick, setCalendarClick] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const resetDates = () => {
        setDateValue([{
            startDate: null, endDate: null, key: 'selection'
        }]);
    };

    const handleCloseCalendar = () => {
        setCalendarClick(false);
    }
    const handleDateValueChange = (newDateValue) => {
        setDateValue(newDateValue);
        setCalendarClick(false)
    }

    const handleCalendarClick = () => {
        setCalendarClick(!calendarclick);
    }

    const handleClick = (btnvalue, selectedChart) => {
        setDayValue(btnvalue);
        setSelectedButton(btnvalue);
        resetDates()
    };

    useEffect(() => {
        handleClick('year'); // Automatically select the 'year' button
    }, []); // Empty dependency array means it runs only on mount

    useEffect(() => {
        axiosInstance
            .get('/get_bins')
            .then((response) => {
                setBinIds(response.data.map((item) => item.unique_identifier));
                const foundData = response.data.find(item => item.unique_identifier === id);
                console.log('/get_bins', foundData)
                setBin(foundData);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [id]);

    // Fetch waste data
    useEffect(() => {
        const fetchTotalWasteProduced = async () => {
            try {
                const response = await axiosInstance.get("/totalwasteproduced", {
                    params: {
                        wasteitem: currentWasteItem, id: id, day: dayvalue,
                    },
                });

                setPercentageChange(parseFloat(response.data.percentage_change).toFixed(2));
                setColorWaste(response.data.color);
                setTotalWasteProduced(response.data.total_waste);
                setTotalWasteWeight(response.data.waste_weight);

            } catch (error) {
                console.error('Error fetching Total waste data:', error);
            } finally {
                setLoading(false);
            }
        };

        if (!loading) {
            fetchTotalWasteProduced();
        }
    }, [currentWasteItem, id, dayvalue]);

    useEffect(() => {
        const fetchtotalco2savedata = debounce(async () => {
            try {
                const response = await axiosInstance.get('/totalco2saved', {
                    params: {
                        wasteitem: currentWasteItem, id: id, day: dayvalue,
                    },
                }); // Adjust the endpoint
                console.log('/totalco2saved', response)
                setPercentageChange(parseFloat(response.data.percentage_change).toFixed(2))
                setColorWaste(response.data.color)
                setTotalWasteProduced(response.data.total_co2_current)
            } catch (err) {
                console.log(err);
                setError('Something went wrong, please try again later.');
            }
        }, 100); // increase the number if you want the pull frequency reduced

        fetchtotalco2savedata();
    }, [dayvalue, currentWasteItem, id]);



    const handleDropdownChange = (event) => {
        setSelectedStream(event.target.value);
        setCurrentWasteItem(event.target.value);
    };

    const handleChartChange = (event) => {
        setSelectedChart(event.target.value);
    };

    const handleBinDropDownChange = (event) => {
        setCurrentBinId(event.target.value);

    };


    const translations = {
        day: 'dag',
        week: 'week',
        month: 'maand',
        '3months': '3 maanden',
        '6months': '6 maanden',
        year: 'jaar'
    };
    const translatedSelectedButton = language === "Dutch" ? translations[selectedButton] : selectedButton;
    const message = `Totaal Afval in Vergelijking Met Vorige ${translatedSelectedButton}`;
    const message1 = `Totaal CO2 Besparing ${translatedSelectedButton}`;
    const message2 = `samenstelling van het substraat ${translatedSelectedButton}`;


    return (

        <div className="bg-white dark:bg-plaex-dark dark:text-white min-h-screen pt-16">
            <div className="sticky top-16 z-30 bg-white dark:bg-plaex-dark shadow-md w-full">
                <div className="px-4 py-4 ">
                    <div className="Waste-Filter-Container px-8">
                        <div
                            className="Waste-Filter-Container-title sm:flex-col xs:flex-col lg:flex-row md:flex-row text-xl sm:-mt-4 xs:-mt-4 lg:mt-0 md:mt-0 w-full lg:justify-start xs:justify-center sm:justify-center md:justify-start items-center capitalize flex flex-row gap-x-2 sm:gap-y-3 xs:gap-y-3">
                            <div className="flex flex-row lg:flex-row md:flex-row sm:flex-col xs:flex-col space-3">
                                <span className="pt-1 text-xl font-semibold xs:text-center">
                                    {id}
                                </span>
                                <div className="flex space-x-3 ml-2">
                                    <select onChange={handleDropdownChange}
                                            className="border-2 w-full border-plaex-bar flex items-center justify-center rounded-lg text-sm lg:ml-auto md:ml-0 sm:ml-0 xs:ml-0">
                                        <option
                                            value="organic">{language === "Dutch" ? "Biologisch" : "Organic"}</option>
                                        <option value="PMD">{language === "Dutch" ? "PMD" : "PMD"}</option>
                                        <option value="paper">{language === "Dutch" ? "Papier" : "Paper"}</option>
                                        <option
                                            value="residual">{language === "Dutch" ? "Restafval" : "Residual"}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div
                            className="Filter-Buttons grid lg:grid-cols-8 md:grid-cols-5 sm:grid-cols-4 xs:grid-cols-3 lg:w-auto md:w-auto xs:w-full sm:w-full sm:px-5 xs:px-5 lg:px-0 md:px-0">
                            <Button
                                text={language === "Dutch" ? "1 Dag" : "1 Day"}
                                onClick={(event) => handleClick("day", selectedChart)}
                                value="day"
                                className={selectedButton === 'day' ? 'selected' : ''}
                            />
                            <Button
                                text="1 Week"
                                onClick={(event) => handleClick("week", selectedChart)}
                                value="week"
                                className={selectedButton === 'week' ? 'selected' : ''}
                            />
                            <Button
                                text={language === "Dutch" ? "1 Maand" : "1 Month"}
                                onClick={(event) => handleClick("month", selectedChart)}
                                value="month"
                                className={selectedButton === 'month' ? 'selected' : ''}
                            />
                            <Button
                                text={language === "Dutch" ? "3 Maanden" : "3 Months"}
                                onClick={(event) => handleClick("3months", selectedChart)}
                                value="3months"
                                className={selectedButton === '3months' ? 'selected' : ''}
                            />
                            <Button
                                text={language === "Dutch" ? "6 Maanden" : "6 Months"}
                                onClick={(event) => handleClick("6months", selectedChart)}
                                value="6months"
                                className={selectedButton === '6months' ? 'selected' : ''}
                            />
                            <Button
                                text={language === "Dutch" ? "1 Jaar" : "1 Year"}
                                onClick={(event) => handleClick("year", selectedChart)}
                                value="year"
                                className={selectedButton === 'year' ? 'selected' : ''}
                            />

                        </div>
                    </div>
                </div>
            </div>

            <div className="px-4 lg:px-8 pt-6">
                <Waste id={id} currentWasteItem={currentWasteItem} dayvalue={dayvalue} language={language}
                       message1={message2} selectedButton={selectedButton} colorwaste={colorwaste}
                       percentagechange={percentagechange} TotalWasteWeight={TotalWasteWeight}
                       selectedValue={selectedValue} setSelectedValue={setSelectedValue}/>
                <SavingsChart id={id} currentWasteItem={currentWasteItem} dayvalue={dayvalue} datevalue={datevalue}
                              language={language} message1={message1} selectedButton={selectedButton}
                              totalwasteproduced={totalwasteproduced} colorwaste={colorwaste}
                              percentagechange={percentagechange}/>
                <div className="grid lg:grid-cols-7 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-4 pt-10">
                    <div className="col-span-3 mt-5 px-8">
                        <WasteGeneration id={id} wasteitem={currentWasteItem} dayvalue={dayvalue}
                                         datevalue={datevalue}/>
                    </div>
                    <div className="col-span-4 mr-10">
                        <BinLineChart id_={id} currentWasteItem_={currentWasteItem} dayvalue_={dayvalue}
                                      datevalue_={datevalue} selectedButton_={selectedButton}
                                      selectedValue_={selectedValue}
                                      setSelectedValue_={setSelectedValue} bin_={bin}/>
                    </div>
                </div>
                <BubbleChart id={id} currentWasteItem={currentWasteItem} dayvalue={dayvalue} language={language}
                             message1={message2} selectedButton={selectedButton}
                             totalwasteproduced={totalwasteproduced}
                             colorwaste={colorwaste} percentagechange={percentagechange}
                             TotalWasteWeight={TotalWasteWeight}/>
                <SubclassDisplay id={id} currentWasteItem={currentWasteItem} dayvalue={dayvalue} language={language}
                                 message1={message2} selectedButton={selectedButton}
                                 totalwasteproduced={totalwasteproduced} colorwaste={colorwaste}
                                 percentagechange={percentagechange} TotalWasteWeight={TotalWasteWeight}/>
            </div>
        </div>

    );
};

export default Dashboard;

