import { useState, useEffect, useContext } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './Calendar.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LanguageContext } from 'context/LanguageContext';
import { nl } from 'date-fns/locale';

const CustomCalendar = ({ datevalue, onDateValueChange }) => {
  const [daterange, setDaterange] = useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection'
    }
  ]);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1180);
  const {language} = useContext(LanguageContext);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1180);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSelect = (ranges) => {
    setDaterange([ranges.selection]);
  }

  const handleApply = () => {
    onDateValueChange(daterange);
    notifyDateValueset();

  }

  const notifyDateValueset = () => {
    const message = language === "Dutch" ? "Succesvol Ingesteld!" : "Successfully Set!";
    toast(message, {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    progressClassName: 'my-progress-bar'
    })};

    const startDatePlaceholder = language === 'Dutch' ? 'Vroeg' : 'Early';
    const endDatePlaceholder = language === 'Continu' ? 'Einddatum' : 'Continuous';

  return (
    <div className='flex flex-col items-center justify-center py-5 w-full z-50 bg-white'>

    <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      
      <DateRangePicker
        onChange={handleSelect}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={2}
        ranges={daterange}
        direction={isMobile ? 'vertical' : 'horizontal'} 
        maxDate={new Date()}
        minDate={new Date('2020')}
        locale={language === 'Dutch' ? nl : undefined}
        rangeColors={["#008000"]}
      />
       <button onClick={handleApply} className='bg-black mt-3 rounded-lg w-24 p-2 text-[12px] text-white hover:bg-gray-300 hover:text-black'>{ language === "Dutch" ? "Toepassen": "Apply"}</button>
    </div>
  );
};

export default CustomCalendar;