import React, { useState } from 'react';
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import WasteItems from "./Components/Waste-Items/Waste-Items";
import WasteLevelsChart from "./Components/Waste-Levels-Chart/Waste-Levels-Chart";

const Waste = ({
  id,
  currentWasteItem,
  dayvalue,
  language,
  message,
  selectedButton,
  totalwasteproduced,
  colorwaste,
  percentagechange,
  TotalWasteWeight,
  selectedValue,
  setSelectedValue
}) => {

  const [totalwasteproduceddata, setTotalWasteProducedData ] = useState('');
  const [wasteweight, setWasteWeight] =  useState("");


  const handleTotalWasteProducedData = (data) => {
    setTotalWasteProducedData(data);
  };

  return (
    <div className="min-h-screen bg-white dark:bg-plaex-dark dark:text-white">
      <div className="w-full px-8 py-4 pt-16 mt-4 sm:mt-70">
        {/* Waste Level Chart  */}
        <div className="flex flex-col sm:flex-row sm:space-x-4 mb-4 justify-center">
          <div className="mt-4 flex flex-col items-center justify-center w-full sm:w-auto">
            <span className="text-green-700 text-xs sm:text-sm text-center mb-2">
              {language === "Dutch" ? `${message}` : `Total Waste compared to previous ${selectedButton}`}
            </span>
            <div className="flex flex-col sm:flex-row items-center justify-center gap-2 sm:gap-4">
              <span className="font-semibold text-sm sm:text-base text-center text-2xl font-semibold">
                {totalwasteproduceddata} <span className="font-light">Objects</span> | {TotalWasteWeight} <span className="font-light">KG</span>
              </span>
              <span className={`text-${colorwaste}-500 flex items-center justify-center text-xs sm:text-sm`}>
                {percentagechange}% {colorwaste === "red" ? <AiOutlineArrowUp/> : <AiOutlineArrowDown/>}
              </span>
            </div>
          </div>
        </div>

        <div className="relative mb-8">
          <WasteLevelsChart id={id} wasteitem={currentWasteItem} dayvalue={dayvalue} handleTotalWasteProducedData={handleTotalWasteProducedData} />
        </div>

        {setSelectedValue(selectedButton)}

        {/* Waste Items Table  */}
        <div className="mb-8">
          <h1 className="text-2xl font-semibold mb-4 text-center">
            {language === "Dutch" ? "Afvalartikelen" : "Waste Items"}
          </h1>
          <WasteItems id={id} value={selectedValue} wastevalue={currentWasteItem} />
        </div>
      </div>
    </div>
  );
};

export default Waste;