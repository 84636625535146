import React, {useState, useEffect, useContext} from "react";
import {MdOutlineArrowForward} from "react-icons/md";
import {LanguageContext} from "context/LanguageContext";
import axiosInstance from "api/axios";
import Table from "./BinStatus";
import {Reorder} from "framer-motion";
import debounce from 'lodash/debounce';

export default function BinTable({onBinClick, onoff}) {
    const [bins, setBins] = useState([]);
    const [bintype, setBinType] = useState(true);
    const [selectedButton, setSelectedButton] = useState('');
    const {language} = useContext(LanguageContext);
    const [showAll, setShowAll] = useState(false);
    const [displayCount, setDisplayCount] = useState(4);

    useEffect(() => {
        const fetchBins = async () => {
            try {
                const response = await axiosInstance.get("/get_bins");
                const nonWeightScaleBins = response.data;
                setBins(nonWeightScaleBins);

                for (let i = 0; i < response.data.length; i++) {
                    const binObj = response.data[i];
                    if (binObj.is_garfill === true) {
                        setBinType(false);
                    }
                }
            } catch (error) {
                console.error("Error fetching bin data:", error);
            } finally {
                // Poll again after 2 minutes
                //setTimeout(fetchBins, 120000); // 120000ms = 2 minutes
                setTimeout(fetchBins, 30000);
            }
        };

        fetchBins(); // Initial fetch call

        return () => {
            // Cleanup not required for `setTimeout` unless external dependencies need cleanup
        };
    }, []); // Empty dependency array to ensure effect runs once on mount


    const handleBinClick = (bin, bin_ident) => {
        onBinClick(bin);
        setSelectedButton(bin_ident);
    };

    const displayedBins = showAll ? bins : bins.slice(0, displayCount);

    return (
        <div className="mt-10 px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between items-center pb-4">
                <h2 className="text-2xl font-semibold  mt-8 text-center mx-auto w-fit">
                     {"Live Feed"}
                </h2>
            </div>

            <div className="overflow-x-auto">
                <Reorder.Group values={displayedBins} onReorder={setBins}>
                    <table className="w-full">
                        <thead>
                        <tr className="text-left text-sm font-semibold border-b border-gray-200">
                            <th className="py-2 px-4 w-[10%]">{language === "Dutch" ? "Nee." : "Bin."}</th>
                            <th className="py-2 px-4 w-[10%]">{language === "Dutch" ? "Plaats" : "Location"}</th>
                            <th className="py-2 px-4 w-[80%] hidden sm:table-cell">{language === "Dutch" ? "Vulniveaus" : "Fill Levels"}</th>
                            <th className="py-2 px-4 w-[10%]">{language === "Dutch" ? "Toestand" : "Status"}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {displayedBins.map((bin) => (
                            <Reorder.Item
                                as="tr"
                                key={bin.id}
                                value={bin}
                                onClick={() => handleBinClick(bin, bin.unique_identifier)}
                                className={`cursor-pointer hover:bg-gray-100 ${
                                    selectedButton === bin.unique_identifier ? 'bg-green-100' : ''
                                }`}
                            >
                                <td className="py-4 px-4 align-top">
                                    <span className="uppercase truncate text-sm">{bin.unique_identifier}</span>
                                </td>
                                <td className="py-4 px-4 align-top">
                                    <span className="truncate text-sm">{bin.bin_location}</span>
                                </td>
                                <td className="py-4 px-4 hidden sm:table-cell">
                                    <Table BinUniqueIdentifier={bin.unique_identifier}/>
                                </td>
                                <td className="py-4 px-4 text-right">
                                    {onoff[bin.unique_identifier] !== undefined ? (
                                        <span className={`inline-block px-2 py-1 rounded-full text-xs ${
                                            onoff[bin.unique_identifier] ? "bg-green-200 text-green-800" : "bg-red-200 text-red-800"
                                        }`}>
                        {language === "Dutch"
                            ? onoff[bin.unique_identifier] ? "Op" : "Uit"
                            : onoff[bin.unique_identifier] ? "Online" : "Offline"}
                      </span>
                                    ) : (
                                        <span
                                            className="bg-red-200 text-red-800 inline-block px-2 py-1 rounded-full text-xs">
                        {language === "Dutch" ? "Uit" : "Offline"}
                      </span>
                                    )}
                                </td>
                            </Reorder.Item>
                        ))}
                        </tbody>
                    </table>
                </Reorder.Group>
                <div className="sm:hidden">
                    {displayedBins.map((bin) => (
                        <div
                            key={bin.id}
                            onClick={() => handleBinClick(bin, bin.unique_identifier)}
                            className={`py-4 border-b cursor-pointer hover:bg-gray-100 ${
                                selectedButton === bin.unique_identifier ? 'bg-green-100' : ''
                            }`}
                        >
                            <div className="flex justify-between items-center mb-2">
                                <span
                                    className="uppercase truncate text-sm font-semibold">{bin.unique_identifier}</span>
                                {onoff[bin.unique_identifier] !== undefined ? (
                                    <span className={`inline-block px-2 py-1 rounded-full text-xs ${
                                        onoff[bin.unique_identifier] ? "bg-green-200 text-green-800" : "bg-red-200 text-red-800"
                                    }`}>
                    {language === "Dutch"
                        ? onoff[bin.unique_identifier] ? "Op" : "Uit"
                        : onoff[bin.unique_identifier] ? "Online" : "Offline"}
                  </span>
                                ) : (
                                    <span
                                        className="bg-red-200 text-red-800 inline-block px-2 py-1 rounded-full text-xs">
                    {language === "Dutch" ? "Uit" : "Offline"}
                  </span>
                                )}
                            </div>
                            <div className="text-sm mb-2">{bin.bin_location}</div>
                            <Table BinUniqueIdentifier={bin.unique_identifier}/>
                        </div>
                    ))}
                </div>
            </div>
            {bins.length > displayCount && (
                <div className="flex justify-end mt-4">
                    <button
                        onClick={() => setShowAll(!showAll)}
                        className="text-gray-600 hover:text-gray-800 flex items-center text-sm font-medium"
                        aria-expanded={showAll}
                        aria-controls="bin-table"
                    >
                        {showAll ? (language === "Dutch" ? "Toon minder" : "Show less") : (language === "Dutch" ? "Zie alles" : "See All")}
                        <MdOutlineArrowForward className="ml-1 w-4 h-4" aria-hidden="true"/>
                    </button>
                </div>
            )}
        </div>
    );
}