import {BarElement, CategoryScale, Chart, LinearScale, Title} from "chart.js";
import React, {useEffect, useState, useContext, useRef} from "react";
import {Bar} from "react-chartjs-2";
import './Waste-Levels-Chart.css'
import ChartDataLabels from "chartjs-plugin-datalabels";
import axiosInstance from "api/axios";
import {LanguageContext} from "context/LanguageContext";
import * as XLSX from 'xlsx';
import CustomCalendar from "common/Components/Calendar/Calendar";
import {AiTwotoneCalendar, AiOutlineClose} from "react-icons/ai"
import {CSSTransition} from 'react-transition-group';
import {AiFillInfoCircle} from 'react-icons/ai'
import html2canvas from 'html2canvas';


Chart.register(BarElement, CategoryScale, LinearScale, ChartDataLabels, Title);

const WasteLevelsChart = (props) => {

    const chartRef = useRef(null);
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const [chartData, setChartData] = useState({});
    const {id, wasteitem, dayvalue, handleTotalWasteProducedData} = props
    const [dailyvalue, setDailyValue] = useState([])
    const {language} = useContext(LanguageContext);
    const [tier, setTier] = useState('')
    const [selectedFormat, setSelectedFormat] = useState('xlsx');
    const [calendarclick, setCalendarClick] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [iscustomDate, setIsCutomDate] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [datevalue, setDateValue] = useState([
        {
            startDate: null,
            endDate: null,
            key: 'selection'
        }
    ]);

    useEffect(() => {
        resetDates();
    }, [dayvalue]);

    const resetDates = () => {
        setDateValue([
            {
                startDate: null,
                endDate: null,
                key: 'selection'
            }
        ]);
    };


    const handleCloseCalendar = () => {
        setCalendarClick(false);
    }
    const handleDateValueChange = (newDateValue) => {
        setDateValue(newDateValue);
        setCalendarClick(false)
    }

    const handleCalendarClick = () => {
        setCalendarClick(!calendarclick);
    }

    const handleModalClick = () => {
        setModalIsOpen(false)
    }

    useEffect(() => {
        let startDate = new Date(datevalue[0].startDate);
        let endDate = new Date(datevalue[0].endDate);

        const defaultDateString = 'Thu Jan 01 1970 03:00:00 GMT+0300 (East Africa Time)';

        if (startDate.toString() === defaultDateString) {
            startDate = null;
        }

        if (endDate.toString() === defaultDateString) {
            endDate = null;
        }

        // Only make the API call if both startDate and endDate are not null
        if (startDate && endDate) {
            axiosInstance.get('/wastedetail', {
                params: {
                    wasteitem: wasteitem,
                    id: id,
                    day: dayvalue,
                    startDate: startDate.toString(),
                    endDate: endDate.toString(),
                },
            })
                .then((response) => {
                    console.log('/wastedetail', response);
                    setDailyValue(response.data);
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            axiosInstance.get('/wastedetail', {
                params: {
                    wasteitem: wasteitem,
                    id: id,
                    day: dayvalue,
                },
            })
                .then((response) => {
                    setDailyValue(response.data);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [dayvalue, wasteitem, id, datevalue]);


    useEffect(() => {
        axiosInstance
            .get("/usertier")
            .then((response) => {
                setTier(response.data.tier)
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const translateToDutch = (text) => {
        const translations = {
            "Monday": "Maandag",
            "Tuesday": "Dinsdag",
            "Wednesday": "Woensdag",
            "Thursday": "Donderdag",
            "Friday": "Vrijdag",
            "Saturday": "Zaterdag",
            "Sunday": "Zondag",
            "January": "Januari",
            "February": "Februari",
            "March": "Maart",
            "April": "April",
            "May": "Mei",
            "June": "Juni",
            "July": "Juli",
            "August": "Augustus",
            "September": "September",
            "October": "Oktober",
            "November": "November",
            "December": "December",
            "organic": "Biologisch",
            "PMD": "PMD",
            "paper": "Papier",
            "residual": "Resterend",
            "rest": "Resterend",
        };
        return translations[text] || text;
    }

    const exportToExcel = (dailyValue) => {
        // Create a new workbook
        const wb = XLSX.utils.book_new();

        // Prepare the data
        let wastevalue = language === "Dutch" ? translateToDutch(wasteitem) : wasteitem;
        let streamvalue = language === "Dutch" ? "IndividueleStroom" : "IndividualStream"

        const data = Object.keys(dailyValue).map(key => {
            let translatedKey = language === "Dutch" ? translateToDutch(key) : key;
            let Date = language === "Dutch" ? "Datum" : "Date";

            return {
                [Date]: translatedKey,
                [wastevalue]: dailyValue[key],
            };
        });

        const ws = XLSX.utils.json_to_sheet(data);

        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        const date = new Date();
        const formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;

        XLSX.writeFile(wb, `${streamvalue}_${wastevalue}_${formattedDate}.xlsx`);
    };


    const exportToCSV = (dailyValue) => {
        let wastevalue = language === "Dutch" ? translateToDutch(wasteitem) : wasteitem;
        let streamvalue = language === "Dutch" ? "IndividueleStroom" : "IndividualStream";

        // Prepare CSV data
        const csvData = [];
        const headerRow = ["Date", wastevalue]; // Modify headers as needed
        csvData.push(headerRow);

        Object.keys(dailyValue).forEach((key) => {
            let translatedKey = language === "Dutch" ? translateToDutch(key) : key;

            const rowData = [translatedKey, dailyValue[key]]; // Modify data fields as needed
            csvData.push(rowData);
        });

        // Convert data to CSV string
        const csvContent = csvData.map((row) => row.join(",")).join("\n");

        // Create a Blob and trigger a download
        const blob = new Blob([csvContent], {type: "text/csv"});
        if (window.navigator.msSaveBlob) {
            // For IE
            window.navigator.msSaveBlob(blob, `${streamvalue}_${wastevalue}.csv`);
        } else {
            // For modern browsers
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = `${streamvalue}_${wastevalue}.csv`;
            link.style.display = "none";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };


    useEffect(() => {
        const labels = Object.keys(dailyvalue).map(label => language === 'Dutch' ? translateToDutch(label) : label);
        const data = Object.values(dailyvalue)
        handleTotalWasteProducedData(Math.max(...data))

        setChartData({
            labels: labels,
            datasets: [
                {
                    label: "Waste Amount",
                    data: data,
                    backgroundColor: wasteitem === 'organic' ? 'rgb(31,73,2)' :
                        wasteitem === 'PMD' ? 'rgb(234, 211, 0)' :
                            wasteitem === 'paper' ? 'rgb(77, 136, 255)' :
                                wasteitem === 'residual' ? 'rgb(176, 127, 0)' :
                                    'rgb(31,73,2)',
                    barThickness: 50,
                    borderRadius: 20,
                    datalabels: {
                        color: "white",
                        font: {
                            size: 14,
                            weight: 'bold',
                        },
                    },
                },
            ],
        });
    }, [dailyvalue]);

    const handleExporttoExcel = (option) => {
        if (option === 'xlsx') {
            exportToExcel(dailyvalue);
        } else if (option === 'csv') {
            exportToCSV(dailyvalue);
        } else if (option === 'png') {
            exportChartAsPNG();
        }
    }


    const exportChartAsPNG = () => {

        const chartSection = document.getElementById('IndividualStreamChart');

        html2canvas(chartSection).then(canvas => {

            const image = canvas.toDataURL('image/png');

            // Generate filename
            let wastevalue = language === "Dutch" ? translateToDutch(wasteitem) : wasteitem;
            let streamvalue = language === "Dutch" ? "IndividueleStroom" : "IndividualStream";
            const date = new Date();
            const formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
            const filename = `${streamvalue}_${wastevalue}_${date}.png`;

            // Pass filename to save function
            saveDataURLToFile(image, filename);

        });

    };
    const saveDataURLToFile = (dataURL, filename) => {

        // convert data URL to blob
        const blob = dataURLToBlob(dataURL);

        // create object URL from blob
        const url = URL.createObjectURL(blob);

        // create anchor tag
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;

        // click to download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    };

    const dataURLToBlob = (dataURL) => {
        // Convert base64 to raw binary data held in a string
        const byteString = atob(dataURL.split(',')[1]);

        const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];

        const arrayBuffer = new ArrayBuffer(byteString.length);

        const ia = new Uint8Array(arrayBuffer);

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        const blob = new Blob([arrayBuffer], {type: mimeString});
        return blob;

    };


    return (
        <div className="w-full">
            {tier === 'Business' && (
                <div className="flex flex-wrap items-center justify-between mb-4">
                    <div className="flex items-center space-x-2 mb-2 sm:mb-0">
                        <AiFillInfoCircle
                            className="h-5 w-5 text-plaex-bar hover:text-green-500 cursor-pointer"
                            onClick={() => setModalIsOpen(!modalIsOpen)}
                        />
                        <AiTwotoneCalendar
                            className="text-2xl text-plaex_calendar transition-transform duration-300 ease-in-out transform hover:scale-90 cursor-pointer"
                            onClick={handleCalendarClick}
                        />
                    </div>
                    <div className="relative">
                        <button
                            className="px-3 py-1 text-sm bg-green-500 text-white rounded hover:bg-green-600 transition-colors duration-200"
                            onClick={toggleDropdown}
                        >
                            {language === "Dutch" ? "Exporteren" : "Export"}
                        </button>
                        {isDropdownOpen && (
                            <CSSTransition
                                in={isDropdownOpen}
                                timeout={200}
                                classNames="slide"
                                unmountOnExit
                            >
                                <div
                                    className="absolute right-0 mt-2 bg-white border border-gray-300 rounded shadow-lg z-10">
                                    {['xlsx', 'csv', 'png'].map((format) => (
                                        <button
                                            key={format}
                                            onClick={() => handleExporttoExcel(format)}
                                            className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                                        >
                                            {format.toUpperCase()}
                                        </button>
                                    ))}
                                </div>
                            </CSSTransition>
                        )}
                    </div>
                </div>
            )}

            {calendarclick && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg shadow-lg p-4 w-full max-w-md">
                        <button onClick={handleCloseCalendar}
                                className="text-2xl font-black hover:text-plaex-bar transition-transform duration-300 ease-in-out transform hover:scale-90">
                            <AiOutlineClose/>
                        </button>
                        <CustomCalendar datevalue={datevalue} onDateValueChange={handleDateValueChange}/>
                    </div>
                </div>
            )}

            {modalIsOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
                        <button onClick={handleModalClick}
                                className="text-2xl font-black hover:text-plaex-bar transition-transform duration-300 ease-in-out transform hover:scale-90">
                            <AiOutlineClose/>
                        </button>
                        <p className="mt-4 text-sm">
                            This interactive chart provides a comprehensive view of your personal waste generation over
                            time. You can filter the chart by waste type and date range to analyze trends and patterns
                            in your individual waste stream. Hover over any data point to see the exact figures for a
                            particular time. Use the filters above the chart to focus on specific waste types or date
                            ranges.
                        </p>
                    </div>
                </div>
            )}

            <div className="h-64 sm:h-96">
                {chartData.labels && chartData.datasets && (
                    <Bar
                        id="IndividualStreamChart"
                        data={chartData}
                        plugins={[ChartDataLabels]}
                        ref={chartRef}
                        options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            scales: {
                                x: {
                                    grid: {
                                        display: false,
                                    }
                                },
                                y: {
                                    type: "linear",
                                    display: true,
                                    position: "right",
                                },
                            },
                            plugins: {
                                legend: {
                                    display: false,
                                },
                                datalabels: {
                                    align: 'center',
                                    anchor: 'center',
                                    display: true,
                                    font: {
                                        size: 10,
                                        weight: 'bold',
                                    },
                                    formatter: (value) => value === 0 ? null : value,
                                },
                            },
                        }}
                    />
                )}
            </div>
        </div>
    );
};


export default WasteLevelsChart;
