import React, {useEffect, useState} from 'react';
import Chart from 'react-apexcharts';
import axiosInstance from 'api/axios';
import CustomCalendar from "common/Components/Calendar/Calendar";
import {AiTwotoneCalendar, AiOutlineClose} from "react-icons/ai"
import {AiFillInfoCircle} from 'react-icons/ai'

const SubclassDisplayChart = (props) => {
    const {id, wasteitem, dayvalue} = props;
    const [series, setSeries] = useState([]);
    const [categories, setCategories] = useState([]);
    const [calendarclick, setCalendarClick] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [iscustomDate, setIsCutomDate] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [datevalue, setDateValue] = useState([
        {
            startDate: null,
            endDate: null,
            key: 'selection'
        }
    ]);

    useEffect(() => {
        resetDates();
    }, [dayvalue]);

    const resetDates = () => {
        setDateValue([
            {
                startDate: null,
                endDate: null,
                key: 'selection'
            }
        ]);
    };


    const handleCloseCalendar = () => {
        setCalendarClick(false);
    }
    const handleDateValueChange = (newDateValue) => {
        setDateValue(newDateValue);
        setCalendarClick(false)
    }

    const handleCalendarClick = () => {
        setCalendarClick(!calendarclick);
    }

    const handleModalClick = () => {
        setModalIsOpen(false)
    }

    function subLabelToHexColor(subLabel) {
        let hash = 0;

        // Generate a hash from the subLabel
        for (let i = 0; i < subLabel.length; i++) {
            hash = subLabel.charCodeAt(i) + ((hash << 5) - hash);
        }

        const baseHue = Math.abs(hash) % 360; // Base hue for color generation
        const colorCount = 21; // Number of colors to generate (increase this if needed)
        const colorArray = [];

        // To track used colors and avoid duplicates
        const usedColors = new Set();

        // Increase hue offset to make colors more distinct
        const hueOffset = 200; // Increase this value to add more space between colors

        for (let i = 0; i < colorCount; i++) {
            const hue = (baseHue + i * hueOffset) % 360; // Adjust hue for variation
            const saturation = 0.7; // Saturation level
            const value = 0.8; // Brightness level

            const rgb = hsvToRgb(hue, saturation, value);
            const hex = rgbToHex(rgb.r, rgb.g, rgb.b);

            // Check for duplicates and avoid them
            if (!usedColors.has(hex)) {
                colorArray.push(hex); // Add the unique hex color to the array
                usedColors.add(hex);  // Mark this color as used
            } else {
                // If color already used, decrement to try another shade
                i--;
            }
        }

        return JSON.stringify(colorArray); // Return as a formatted string
    }


    function hsvToRgb(h, s, v) {
        const c = v * s;
        const x = c * (1 - Math.abs((h / 60) % 2 - 1));
        const m = v - c;

        let r, g, b;

        if (h < 60) {
            [r, g, b] = [c, x, 0];
        } else if (h < 120) {
            [r, g, b] = [x, c, 0];
        } else if (h < 180) {
            [r, g, b] = [0, c, x];
        } else if (h < 240) {
            [r, g, b] = [0, x, c];
        } else if (h < 300) {
            [r, g, b] = [x, 0, c];
        } else {
            [r, g, b] = [c, 0, x];
        }

        return {
            r: Math.round((r + m) * 255),
            g: Math.round((g + m) * 255),
            b: Math.round((b + m) * 255),
        };
    }

    function rgbToHex(r, g, b) {
        return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
    }

    const generateDistinctColors = (numColors) => {
        const colors = [];
        for (let i = 0; i < numColors; i++) {
            const hue = i * 360 / numColors;
            const saturation = 0.7;
            const value = 0.8;
            const rgb = hsvToRgb(hue, saturation, value);
            colors.push(rgbToHex(rgb.r, rgb.g, rgb.b));
        }
        return colors;
    };


    useEffect(() => {
        let startDate = new Date(datevalue[0].startDate);
        let endDate = new Date(datevalue[0].endDate);

        const defaultDateString = 'Thu Jan 01 1970 03:00:00 GMT+0300 (East Africa Time)';

        if (startDate.toString() === defaultDateString) {
            startDate = null;
        }

        if (endDate.toString() === defaultDateString) {
            endDate = null;
        }

        axiosInstance
            .get('/subclassdisplay', {
                params: {
                    id: id,
                    day: dayvalue,
                    startDate: startDate,
                    endDate: endDate,
                }
            })
            .then(response => {
                const data = response.data;
                console.log('/subclassdisplay', data);
                const labels = Object.keys(data);
                const sublabels = Array.from(new Set(labels.flatMap(label => Object.keys(data[label]))));

                const distinctColors = generateDistinctColors(sublabels.length);

                const series = sublabels.map((sublabel, index) => {
                    return {
                        name: sublabel,
                        data: labels.map(label => data[label][sublabel] || 0),
                        color: distinctColors[index], // Assign a unique color to each series
                    };
                });

                setSeries(series);
                setCategories(labels);
            })
            .catch(error => {
                console.error(error);
            });
    }, [dayvalue, wasteitem, id, datevalue]);

    const options = {
        chart: {
            type: 'bar',
            height: 400,
            stacked: true,
            borderRadius: 10,
        },
        plotOptions: {
            bar: {
                horizontal: true,
                barHeight: '40%',
            },
        },
        stroke: {
            width: 1,
            colors: ['#fff']
        },
        xaxis: {
            categories: categories,
            max: 100,
            title: {
                text: '% Contributions', // Set x-axis label
            },
        },
        fill: {
            type: 'solid', // Use solid fill instead of gradient
        },
        colors: series.map(s => s.color), // Use the colors assigned to each series
        legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
        },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                // Check if the value is numeric
                if (!isNaN(val)) {
                    // If the value is numeric, append a percentage sign to it
                    return val.toFixed(1) + "%";
                } else {
                    // If the value is not numeric, return it as it is
                    return val;
                }
            },
            style: {
                colors: ['#fff']
            }
        },
    };

    return (
        <div className="Waste-Chart-Container relative px-8">
            <div className='flex items-center'>
                <AiFillInfoCircle
                    className="h-5 w-5 text-plaex-bar hover:text-green-500 cursor-pointer"
                    onClick={() => setModalIsOpen(!modalIsOpen)}
                />
                <div className='flex justify-start'>
                    <AiTwotoneCalendar
                        className="text-2xl text-plaex_calendar transition-transform duration-300 ease-in-out transform hover:scale-[0.8] cursor-pointer"
                        onClick={handleCalendarClick}/>
                </div>
            </div>


            <Chart options={options} series={series} type="bar" height={450}/>
            {calendarclick && (
                <div
                    className={calendarclick ? "z-50 flex flex-col bg-white rounded-lg shadow-lg absolute top-0 w-full" : "z-50 flex flex-col rounded-lg shadow-lg absolute w-full bg-white"}>
                    <div className="flex items-start justify-start">
                        <button onClick={handleCloseCalendar}
                                className="text-2xl font-black ml-6 mt-6 hover:text-plaex-bar transition-transform duration-300 ease-in-out transform hover:scale-[0.8] ">
                            <AiOutlineClose/></button>
                    </div>
                    <CustomCalendar datevalue={datevalue} onDateValueChange={handleDateValueChange}/>
                </div>
            )}

            {modalIsOpen && (
                <>
                    <div className="fixed inset-0 bg-black opacity-50 z-40"></div>
                    <div className="fixed inset-0 flex items-center justify-center z-50">
                        <div className="bg-white rounded-lg shadow-lg p-6 pb-10 z-50 w-full sm:max-w-md">
                            <button onClick={handleModalClick}
                                    className="text-2xl font-black ml-6 mt-6 hover:text-plaex-bar transition-transform duration-300 ease-in-out transform hover:scale-[0.8] ">
                                <AiOutlineClose/></button>
                            <div>
                                This interactive chart provides a visual representation of the subclasses each waste is
                                associated with. Each data point on the chart corresponds to a specific subclass of
                                waste for a specific stream, allowing you to visualize the different waste classes
                                included inside of a waste steam. Toggle between the date filters to see the different
                                subclasses recorded for different time periods.
                            </div>

                        </div>
                    </div>
                </>
            )}
        </div>


    );
};

export default SubclassDisplayChart;

