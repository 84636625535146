import axiosInstance from "api/axios";
import React, {useEffect, useState, useContext} from "react";
import {LanguageContext} from "context/LanguageContext";
import {
    AiFillDatabase,
    AiFillLeftSquare,
    AiFillRightSquare,
} from "react-icons/ai";
import "./Waste-Items.css";
import {FiFilter} from 'react-icons/fi';

const WasteItems = (props) => {
  const { id: propId, value, wastevalue } = props;
  const [wasteitem, setWasteItem] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [pagenumber, setPageNumber] = useState(null);
  const { language } = useContext(LanguageContext);
  const [sortDirectionWeight, setSortDirectionWeight] = useState("default");
  const [sortDirectionTime, setSortDirectionTime] = useState("default");
  const [sortBy, setSortBy] = useState('weight');
  const [counter, setCounter] = useState(1);
  const [loading, setLoading] = useState(false);
  const [currentId, setCurrentId] = useState(propId);
  const [currentFilter, setCurrentFilter] = useState('');

  const getAllBinItems = (page = 1) => {
    setLoading(true);
    axiosInstance
      .get("/wasteitem", {
        params: {
          id: "all",
          value: value,
          wastevalue: wastevalue,
          weight: sortDirectionWeight,
          time: sortDirectionTime,
          page: page,
        },
      })
      .then((response) => {
        setLoading(false);
        setWasteItem(response.data.results);
        setPagination(response.data);
        setPageNumber(Math.ceil(response.data.count / 5));
        setCounter(page);
        setCurrentId("all");
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const handleFilterChange = (value) => {
    setCurrentFilter(value);
    switch (value) {
      case 'weightAsc':
        setSortBy('weight');
        setSortDirectionWeight(true);
        break;
      case 'weightDesc':
        setSortBy('weight');
        setSortDirectionWeight(false);
        break;
      case 'timeAsc':
        setSortBy('time');
        setSortDirectionTime(true);
        break;
      case 'all':
        getAllBinItems();
        return;
      case 'timeDesc':
        setSortBy('time');
        setSortDirectionTime(false);
        break;
      case 'clear':
        setSortBy('');
        setSortDirectionTime("default");
        setSortDirectionWeight("default");
        break;
    }
    fetchWasteData(currentId, 1);
  };

  const handlePrevClick = () => {
    if (pagination && pagination.previous) {
      const prevPage = counter - 1;
      setCounter(prevPage);
      fetchWasteData(currentId, prevPage);
    }
  };

  const handleNextClick = () => {
    if (pagination && pagination.next) {
      const nextPage = counter + 1;
      setCounter(nextPage);
      fetchWasteData(currentId, nextPage);
    }
  };

  const fetchWasteData = (id, page = 1) => {
    setLoading(true);
    axiosInstance.get("/wasteitem", {
      params: {
        id: id,
        value: value,
        wastevalue: wastevalue,
        weight: sortDirectionWeight,
        time: sortDirectionTime,
        page: page,
      },
    })
      .then((response) => {
        setWasteItem(response.data.results);
        setPagination(response.data);
        setPageNumber(Math.ceil(response.data.count / 5));
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (propId !== currentId) {
      setCurrentId(propId);
      setCurrentFilter('');
      setCounter(1);
      if (propId === "all") {
        getAllBinItems();
      } else {
        fetchWasteData(propId, 1);
      }
    }
  }, [propId, value, wastevalue]);

  useEffect(() => {
    if (currentId === "all") {
      getAllBinItems(counter);
    } else {
      fetchWasteData(currentId, counter);
    }
  }, [sortDirectionTime, sortDirectionWeight]);

  const translateWasteToDutch = (text) => {
    const translations = {
      "organic": "Biologisch",
      "PMD": "PMD",
      "paper": "papier",
      "rest": "Resterend"
    };
    return translations[text] || text;
  }

  return (
    <div className="w-full">
      {/* Filter Section */}
      <div className="mb-4 flex justify-end">
        <select
          className="text-sm p-2 border rounded-md hover:text-plaex-green focus:outline-none focus:ring-2 focus:ring-green-500"
          onChange={(e) => handleFilterChange(e.target.value)}
          value={currentFilter}
        >
          <option value="" disabled>
            <FiFilter className="inline mr-2"/>
            {language === "Dutch" ? "Sorteren" : "Sort"}
          </option>
          <option value="all">{language === "Dutch" ? "Alle" : "All"}</option>
          <option value="weightAsc">{language === "Dutch" ? "Gewicht Oplopend" : "Weight Ascending"}</option>
          <option value="weightDesc">{language === "Dutch" ? "Gewicht Aflopend" : "Weight Descending"}</option>
          <option value="timeAsc">{language === "Dutch" ? "Datum Oplopend" : "Date Ascending"}</option>
          <option value="timeDesc">{language === "Dutch" ? "Datum Aflopend" : "Date Descending"}</option>
          <option value="clear">{language === "Dutch" ? "Filter Wissen" : "Clear Filter"}</option>
        </select>
      </div>

      {/* Loading Indicator */}
      {loading ? (
        <div className="text-center p-7">
          <span className="text-gray-500">{language === "Dutch" ? "Bezig met laden..." : "Loading..."}</span>
        </div>
      ) : (
        <>
          {/* Table Section */}
          <div className="overflow-x-auto">
            <table className="w-full border-collapse">
              <thead>
                <tr className="bg-gray-100 dark:bg-gray-700">
                  <th className="p-2 text-left">{language === "Dutch" ? "Nee" : "Bin."}</th>
                  <th className="p-2 text-left">Type</th>
                  <th className="p-2 text-left">{language === "Dutch" ? "Gewicht" : "Weight"}</th>
                  <th className="p-2 text-left">{language === "Dutch" ? "Naam" : "Name"}</th>
                  <th className="p-2 text-left">{language === "Dutch" ? "Datum" : "Date"}</th>
                  <th className="p-2 text-left">{language === "Dutch" ? "Tijd" : "Time"}</th>
                </tr>
              </thead>
              <tbody>
                {wasteitem && wasteitem.map((row) => (
                  <tr key={row.bin.unique_identifier} className="border-b dark:border-gray-600">
                    <td className="p-2 uppercase">{row.bin.unique_identifier}</td>
                    <td className="p-2">{language === 'Dutch' ? translateWasteToDutch(row.waste_type) : row.waste_type === "rest" ? "Residual" : row.waste_type}</td>
                    <td className="p-2">{Math.abs(row.waste_weight)}g</td>
                    <td className="p-2">{row.sub_class_waste_type === "" ? "unidentified" : row.sub_class_waste_type}</td>
                    <td className="p-2">{row.time_collected.split("T")[0]}</td>
                    <td className="p-2">{row.time_collected.split("T")[1].split('.')[0]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination Section */}
          {pagination && (
            <div className="flex items-center justify-center mt-4 space-x-4">
              <button
                disabled={!pagination.previous}
                onClick={handlePrevClick}
                className="p-2 rounded-lg text-gray-500 disabled:opacity-50"
              >
                <AiFillLeftSquare className="text-2xl"/>
              </button>
              <span className="text-sm">
                {counter} of {pagenumber}
              </span>
              <button
                disabled={!pagination.next}
                onClick={handleNextClick}
                className="p-2 rounded-lg text-green-500 disabled:opacity-50"
              >
                <AiFillRightSquare className="text-2xl"/>
              </button>
            </div>
          )}

          {/* No Data Section */}
          {!pagination && wasteitem.length === 0 && (
            <div className="text-center p-7">
              <div className="flex flex-col items-center text-gray-400">
                <AiFillDatabase className="text-4xl mb-2"/>
                <span className="text-sm">{language === "Dutch" ? "Geen Gegevens" : "No Data"}</span>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default WasteItems;
